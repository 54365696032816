import {
  SET_IMAGE,
  SET_SELECTED_IMAGES_DATA,
  SET_CUSTOMIZE_IMAGES_DATA,
  SET_SELECTED_IMAGES,
  SET_TEXT,
  UPDATE_TEXT,
  CLEAR_STORE,
  CROP_IMAGE,
  IMAGE_COORDINATE,
} from "./const";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_IMAGE:
      return {
        ...state,
        signalImage: { ...action.payload },
      };
    case SET_SELECTED_IMAGES_DATA:
      return {
        ...state,
        setSelectedImageData: { ...action.payload },
      };
    case SET_CUSTOMIZE_IMAGES_DATA:
      return {
        ...state,
        setCustomizeImageData: { ...action.payload },
      };
    case SET_SELECTED_IMAGES:
      return {
        ...state,
        setSelectedImages: { ...action.payload },
      };
    case CROP_IMAGE:
      return {
        ...state,
        cropImage: { ...action.payload },
      };
    case IMAGE_COORDINATE:
      return {
        ...state,
        imageCoordinate: { ...action.payload },
      };
    case SET_TEXT:
      return {
        ...state,
        setText: [...action.payload],
      };
    case UPDATE_TEXT:
      const _setText = { ...state.setText };
      const data = action.payload.data;
      _setText[action.payload.selectedLayer] = {
        ..._setText[action.payload.selectedLayer],
        ...data,
      };
      return {
        ...state,
        setText: _setText,
      };
    default:
      return state;
  }
};
