import React from "react";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-7.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import { setText } from "../../../../utils/redux/modules/imageHandler/action";
import { useDispatch } from "react-redux";
import "./index.scss";
import { useTranslation } from "react-i18next";

const DeleteTextModal = ({
  handleCancel,
  handleOk,
  isModalVisible,
  eventData,
  deleteText,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Modal
          className="deleteText-model"
          visible={isModalVisible}
          // handleOk={(e) => handleOk(e)(order, "delete")}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={150} height={80} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={150} height={80} />
          </div>
          <div className="deleteText-logo">
            <img src={logo} alt="" width={100} height={90} />
          </div>
          <div className="deleteText-main d-flex justify-content-center flex-column align-items-center h-100">
            <div className="delivery-logo"></div>
            <div
              className="deleteText-title"
              style={{ textTransform: "uppercase" }}
            >
              {t("common.opps")}
            </div>
            <div
              className="deleteText-details"
              // style={{ width: "360px", textAlign: "center" }}
            >
              {t("DeleteTextModal.text")} "{deleteText?.title}"?
            </div>
            <div className="deleteText-botton">
              <button
                className="buttonCustom mt-3 mr-3"
                onClick={handleCancel}
                style={{
                  backgroundColor: "transparent",
                  color: "#213547",
                  boxShadow: "0px 2px 5px 5px RGBA(0, 0, 0, 0.04)",
                }}
              >
                {t("DeleteTextModal.cancleButton")}
              </button>
              <button
                className="buttonCustom mt-3"
                onClick={() => {
                  handleOk(eventData)(0, "delete");
                  handleCancel();
                  dispatch(setText([]));
                }}
                style={{ backgroundColor: "#BA4E4E " }}
              >
                {t("DeleteTextModal.deleteButton")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeleteTextModal;
