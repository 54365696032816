const envType = process.env.REACT_APP_ENV || "development";

const types = {
  production: {
    BASE_URL: "https://api.gccpickandmix.com/api/v1/",
    CONNECTION_HUB: "https://api.gccpickandmix.com/customimagehub",
    MOBILE_APP_URL: "https://mobile.gccpickandmix.com/",
  },
  development: {
    BASE_URL:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/api/v1/",
    CONNECTION_HUB:
      "https://app-ghirardelliapi-dev-westus-001.azurewebsites.net/customimagehub",
    MOBILE_APP_URL:
      "https://app-ghirardellimobile-dev-westus-001.azurewebsites.net/",
  },
};

export const config = types[envType];
// export const config = types;
