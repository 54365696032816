import React from "react";

const Button = (props) => {
  const { className, onClick, disabled } = props;
  return (
    <>
      <button
        className={`${className}`}
        onClick={onClick}
        disabled={disabled || false}
      >
        {props.children}
      </button>
    </>
  );
};

export default Button;
