import React from "react";
import Loading from "../Loading";

const LoaderBackground = () => {
  return (
    <div className="vh-100 w-100 LoaderBackground">
      <Loading />
    </div>
  );
};

export default LoaderBackground;
