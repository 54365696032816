import { handleActions } from "redux-actions";
import { requestFail, requestPending, requestSuccess } from "../../../fetch";
import { initialKiosksState } from "./initialKioskState";
import { KioskConstant } from "./kioskConstant";

export const kioskReducer = handleActions(
  {
    [requestSuccess(KioskConstant.CHECK_PRINTER_MODE)]: (state, action) => {
      return {
        ...state,
        details: action.payload || {},
        checkPrinterModeLoading: false,
        checkPrinterModeFailure: false,
        checkPrinterModeLoaded: true,
      };
    },
    [requestPending(KioskConstant.CHECK_PRINTER_MODE)]: (state) => ({
      ...state,
      checkPrinterModeLoading: true,
      checkPrinterModeFailure: false,
      checkPrinterModeLoaded: false,
    }),
    [requestFail(KioskConstant.CHECK_PRINTER_MODE)]: (state) => ({
      ...state,
      checkPrinterModeLoading: false,
      checkPrinterModeFailure: true,
      checkPrinterModeLoaded: true,
    }),
    [requestSuccess(KioskConstant.KIOSK_TRANSLATE)]: (state, action) => {
      return {
        ...state,
        kioskTranslateLoding: false,
        kioskTranslateFailure: false,
        kioskTranslateLoaded: true,
      };
    },
    [requestPending(KioskConstant.KIOSK_TRANSLATE)]: (state) => ({
      ...state,
      kioskTranslateLoding: true,
      kioskTranslateFailure: false,
      kioskTranslateLoaded: false,
    }),
    [requestFail(KioskConstant.KIOSK_TRANSLATE)]: (state) => ({
      ...state,
      kioskTranslateLoding: false,
      kioskTranslateFailure: true,
      kioskTranslateLoaded: true,
    }),
  },
  initialKiosksState()
);
