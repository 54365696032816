import React, { useEffect, useRef, useState } from "react";
import { Modal, Slider } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Cropper from "react-cropper";
import logo from "../../../../assets/images/Group 8-5.svg";
import { cropImage } from "../../../../utils/redux/modules/imageHandler/action";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import "cropperjs/dist/cropper.css";
import "./index.scss";

const CropModel = (props) => {
  const {
    handleCancel,
    handleOk,
    isModalVisible,
    setIsModalVisible,
    image,
    setImage,
    zoom,
    setZoom,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState(null);
  const cropper = useRef(null);
  // const [zoom, setZoom] = useState(0);
  const [defaultZoom, setDefaultZoom] = useState(0);
  const crop = () => {
    if (
      cropper.current.cropper.cropBoxData.width !== 250 ||
      cropper.current.cropper.cropBoxData.height !== 250
    ) {
      cropper.current.cropper.setCropBoxData({
        width: 250,
        height: 250,
        left: cropper.current.cropper.cropBoxData.left,
        top: cropper.current.cropper.cropBoxData.top,
      });
      return;
    }
    let croppedData = cropper.current.cropper.getCroppedCanvas().toDataURL();
    const blobBin = atob(croppedData.split(",")[1]);
    let array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: "image/png" });
    const formData = new FormData();
    formData.append("myNewFileName", file);
    setImagePreview(croppedData);
    setFormData(formData);
  };

  const cropSave = () => {
    // cropper.current.cropper.clear();
    // cropper.current.cropper.reset();
    dispatch(cropImage({imagePreview}));
    setImage(imagePreview);
    setIsModalVisible(false);
  };

  const handleChange = (value) => {
    setZoom(value);
  };

  function minusButton() {
    if (zoom > 0) {
      setZoom(zoom - 0.1);
    }
  }

  function pluseButton() {
    setZoom(zoom + 0.1);
  }

  // useEffect(() => {
  //   if (!isModalVisible) {
  //     cropper?.current?.cropper?.reset();
  //     cropper?.current?.cropper?.zoom(0);
  //   }
  // }, [isModalVisible]);

  const handleZoom = () => {
    const zoomValue =
      cropper.current.cropper.canvasData.width /
      cropper.current.cropper.canvasData.naturalWidth;

    let fValue = parseFloat(zoomValue).toFixed(2);
    fValue = +parseFloat(zoomValue).toFixed(2);
    // setZoom(parseInt(zoomValue));
    if (cropper.current.cropper.canvasData.width > 266) {
      setDefaultZoom(fValue + 0.1);
    } else {
      setDefaultZoom(0);
    }
  };

  return (
    <>
      <Modal
        className="crop-model"
        visible={isModalVisible}
        destroyOnClose={true}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        <div className="model-left-logo position-absolute">
          <img src={leftLogo} alt="" width={180} height={120} />
        </div>
        <div className="model-right-logo position-absolute">
          <img src={rightLogo} alt="" width={180} height={120} />
        </div>
        <div className="crop h-100">
          <div className="crop-logo" onClick={handleCancel}>
            <img src={logo} alt="" height={30} />
          </div>
          <div className="crop-title">{t("CropModal.title")}</div>
          <div
            // clasName={"container"}
            className="crop-main-modal crop-image container"
            style={{
              // width: "35%",
              // marginLeft: "32%",
              maxWidth: "550px",
              marginTop: "120px",
              marginBottom: "120px",
              overflow: "hidden",
            }}
          >
            {/*<img src="hubble-extreme-deep-field.jpg" id="hubblepic">*/}
            <div>
              {isModalVisible && (
                <Cropper
                  style={{ height: 266, width: "100%" }}
                  src={image}
                  ref={cropper}
                  zoomTo={zoom}
                  // destroy={isModalVisible}
                  crop={() => crop()}
                  zoomOnWheel={true}
                  // zoom={2}
                  // aspectRatio={1 / 1}
                  viewMode={1.5}
                  minCropBoxHeight={250}
                  minCropBoxWidth={250}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  background={false}
                  autoCropArea={0.5}
                  zoom={() => handleZoom()}
                  // cropBoxResizable={false}
                  dragMode="move"
                  // dragMode={"none"}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  guides={true}
                  // enable={false}
                />
              )}
            </div>
          </div>
          {/* <div className="slider container">
            <button
              className="range_Button-Minus"
              id="minus"
              onClick={() => minusButton()}
            >
              -
            </button> */}
            {/* <Slider
              trackStyle={{ backgroundColor: "#1F366C" }}
              handleStyle={{ borderColor: "#1F366C" }}
              value={zoom}
              min={0}
              max={2}
              step={0.1}
              // min="1"
              // max="10"
              id="zoomer"
              onChange={(e) => handleChange(e)}
              style={{ height: "50%", width: "500px" }}
            /> */}
            {/* <button
              className="range_Button"
              id="plus"
              onClick={() => pluseButton()}
            >
              +
            </button> */}
          {/* </div> */}
          <div className="crop-button d-flex justify-content-center mb-5 mt-5">
            <button
              className="buttonCustom button-cancle"
              onClick={handleCancel}
            >
              {t("CropModal.cancleButton")}
            </button>
            <button
              className="buttonCustom cropSaveButton"
              onClick={() => cropSave()}
            >
              {t("CropModal.crop&SaveButton")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CropModel;
