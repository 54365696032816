import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import chocolateBox from "../../assets/images/tin.png";
import chocolateGift from "../../assets/images/bag.png";
import arrowLeft from "../../assets/images/arrow-left.svg";
import restart from "../../assets/images/restart.svg";
import Button from "../../common/components/Button/Button";
import TimeModal from "../../common/components/TimeModal";
import { CheckOutlined } from "@ant-design/icons";
import { getAllProduct } from "../../utils/redux/modules/Product/productAction";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedImageData } from "../../utils/redux/modules/imageHandler/action";
import Loading from "../../common/components/Loading/index";
import "./index.scss";

const SelectProductType = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.product?.getAllProductLoading);
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const [product, selectPrdocut] = useState([]);
  const [productId, setProductId] = useState();

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = () => {
    const onSuccess = (response) => {
      selectPrdocut(response);
    };
    const onFail = (err) => {
      console.log("error", err);
    };
    dispatch(getAllProduct({ onSuccess, onFail }));
  };

  const [image, setImage] = useState("");

  const onRedirect = (image) => {
    dispatch(setSelectedImageData({ image, productId }));
    props.history.push({
      pathname: `/selectImage/${locationId}`,
      state: { image: image },
    });
  };

  const handleBack = () => {
    props.history.push({ pathname: `/basicInfo/${locationId}` });
  };

  const onSelectImage = (item, i, productId) => {
    setProductId(productId);
    setImage(item);
  };
  const handleRestart = () => {
    props.history.push({ pathname: `/start/${locationId}` });
  };
  return (
    <>
      {loading && <Loading />}
      <BackgroundImage>
        <div className="d-flex justify-content-between">
          <Button className="left-arrow" onClick={handleBack}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={arrowLeft} alt="" />
              <span className="home-back-text">{t("common.back")}</span>
            </div>
          </Button>
          <Button className="right-restart" onClick={handleRestart}>
            <div>
              <span className="home-restart-text">{t("common.restart")}</span>
              <img src={restart} alt="" />
            </div>
          </Button>
        </div>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div
              className="selectProduct-type d-flex flex-column"
              // style={{ gap: "65px" }}
            >
              <div className="selectProdoct-type-top">
                <div className="selectProduct-type-top-text">
                  {t("selectProductType.title")}
                </div>
              </div>
              <div className="selectProdoct-type-center d-flex justify-content-center align-content-center">
                {product.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                        ? "selectPrdocut-type-center-right"
                        : "selectPrdocut-type-center-Left"
                    } position-relative d-flex  justify-content-center flex-column align-items-center ${productId ===
                      index + 1 && "selected-product"}`}
                    onClick={() =>
                      onSelectImage(
                        `${
                          item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                            ? chocolateBox
                            : chocolateGift
                        }`,
                        index,
                        item.productId
                      )
                    }
                  >
                    <div
                      className={`${
                        item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                          ? "d-flex align-items-end h-100"
                          : `${
                              t("selectProductType.product1") ===
                              "BOLSA DE REGALO DE CHOCOLATES MEZCLA PERSONALIZADA"
                                ? "h-67"
                                : "h-70"
                            }`
                      }`}
                    >
                      <img
                        src={`${
                          item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                            ? chocolateBox
                            : chocolateGift
                        }`}
                        alt="Ghirardelli"
                        height={`${
                          item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                            ? "143.82px"
                            : "232px"
                        }`}
                        width={`${
                          item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                            ? "200px"
                            : ""
                        }`}
                      />
                    </div>

                    {productId === index + 1 && (
                      <span className="check-mark">
                        <CheckOutlined style={{ color: "#ffffff" }} />
                      </span>
                    )}
                    <div>
                      <div
                        className={`${
                          item.productName === "PICK & MIX CHOCOLATE GIFT TIN"
                            ? "selectPrdocut-type-center-Right-text"
                            : "selectPrdocut-type-center-Left-text"
                        }`}
                      >
                        {`${
                          index == 0
                            ? t("selectProductType.product1")
                            : t("selectProductType.product2")
                        }`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {!product?.length && (
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <button className="refresh_api" onClick={getProductList}>
                    <img src={restart} alt="" />
                  </button>
                </div>
              )}
              <div className="selectProdoct-type-Down d-flex justify-content-center">
                <button
                  disabled={!productId}
                  className={`${
                    !productId ? "buttonCustomDisable" : "buttonCustom"
                  }`}
                  onClick={() => onRedirect(image)}
                >
                  {t("selectProductType.button")}
                </button>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};
export default SelectProductType;
