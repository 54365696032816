import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-5.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import "./index.scss";

const TermsModel = ({ handleCancel, handleOk, isModalVisible }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <Modal
          className="Terms-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={200} height={120} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={200} height={120} />
          </div>
          <div className="terms-logo position-relative" onClick={handleCancel}>
            <img src={logo} alt="" height={30} />
          </div>
          <div className="terms-title"> {t("Terms.title")}</div>
          <div className="terms-details mt-5 mb-5">
            {t("Terms.text")}
            <div className="fw-500 mt-4 mb-5">{t("Terms.title1")}</div>
            <div className="mt-4 mb-4">{t("Terms.text2")}</div>
            <div className="fw-500" style={{ marginTop: "50px" }}>
              {t("Terms.title2")}
            </div>
            <div className="mt-4 mb-4">{t("Terms.text3")}</div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TermsModel;
