import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../common/components/BackgroundImage";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import Filter from "bad-words";
import MainBox from "../../common/components/MainBox";
import {
  cropImage,
  imageCoordinate,
  setCustomizeImageData,
  setImage,
  setText,
  updateText,
} from "../../utils/redux/modules/imageHandler/action";
import CropModel from "./component/CropModel/index";
import arrowLeft from "../../assets/images/arrow-left.svg";
import restart from "../../assets/images/restart.svg";
import WarningModel from "./component/WarningModel";
import DeleteTextModal from "./component/DeleteTextModal";
import CropImage from "../../assets/images/crop.svg";
import RotateImage from "../../assets/images/rotate.svg";
import FlipImage from "../../assets/images/flip.svg";
import DeleteImage from "../../assets/images/trash.svg";
import DeleteImageModal from "./component/DeleteModel";
import Layers from "./component/TextLayer/TextLayer";
import Button from "../../common/components/Button/Button";
import { errorMessage, errorModal } from "../../utils/common";
import Loading from "../../common/components/Loading";
import "./index.scss";

const { Option } = Select;

const getMousePosition = (e, svgRef) => {
  const svgPoint = svgRef.current.createSVGPoint();
  svgPoint.x = e.clientX;
  svgPoint.y = e.clientY;
  const mousePosition = svgPoint.matrixTransform(
    svgRef.current.getScreenCTM().inverse()
  );
  return mousePosition;
};

const getCenterCoordinates = (coordinates, width, height, scale) => {
  const maxXCoordinate = coordinates.x + width * scale;
  const minXCoordinate = coordinates.x;
  const centerX = (maxXCoordinate + minXCoordinate) / 2;

  const maxYCoordinate = coordinates.y + height * scale;
  const minYCoordinate = coordinates.y;
  const centerY = (maxYCoordinate + minYCoordinate) / 2;

  return {
    x: centerX,
    y: centerY,
    coordinates,
  };
};

const getPrintAreaRect = (initialPrintArea) => {
  const { ratio, top, left } = initialPrintArea;
  const ratioString = ratio.split(":");
  const ratioObj = {
    x: Number(ratioString[0]),
    y: Number(ratioString[1]),
  };
  const rectWidth = (top.x - left.x) * 2;
  return {
    x: left.x,
    y: top.y,
    width: rectWidth,
    height: (ratioObj.y * rectWidth) / ratioObj.x,
    top,
    left,
  };
};

const composeInitialPrintAreaState = () => {
  const printArea = {};
  Object.assign(printArea, {
    ratio: "8:8",
    top: { x: 600, y: 0 },
    left: { x: 0, y: 500 },
  });
  return printArea;
};

const getTextSizeForStyle = (
  text,
  fontSize,
  fontWeight,
  textAnchor,
  fontFamily
) => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  document.body.appendChild(svg);
  const textElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "text"
  );
  textElement.setAttribute("text-rendering", "geometricPrecision");
  textElement.setAttribute("letter-spacing", "0px");
  svg.appendChild(textElement);

  const tspans = text.split("\n").map((line, lineIndex) => {
    const tspan = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "tspan"
    );

    tspan.setAttribute("font-size", String(fontSize));
    tspan.setAttribute("font-weight", fontWeight);
    tspan.setAttribute("font-family", fontFamily);
    tspan.setAttribute("text-anchor", textAnchor);
    tspan.setAttribute("x", "0");
    tspan.setAttribute("y", String((lineIndex + 1) * fontSize));

    tspan.textContent = line;
    return tspan;
  });

  tspans.forEach((tspan) => {
    textElement.appendChild(tspan);
  });

  const box = textElement.getBBox();

  document.body.removeChild(svg);

  return {
    width: box.width,
    height: box.height + 2,
  };
};

const PersonalizeLabel = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const svgRef = useRef();
  const printAreaState = composeInitialPrintAreaState();
  const rect = getPrintAreaRect(printAreaState);
  const rectCenter = getCenterCoordinates(
    { x: rect.x, y: rect.y },
    rect.width,
    rect.height,
    1
  );
  const selectedImage = useSelector((state) => state?.imageData);
  const getImageCoordinate = useSelector(
    (state) => state?.imageData?.imageCoordinate
  );
  const selectedImageLocal = useSelector((state) => state.imageData);
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const getCropImage = useSelector((state) => state?.imageData?.cropImage);
  const getText = useSelector((state) => state?.imageData?.setText);
  let mainImage =
    getCropImage?.imagePreview ??
    selectedImage?.signalImage?.image ??
    selectedImageLocal?.setSelectedImages?.selectedImage?.imageName;
  const previousPath =
    history?.location?.state?.previousPath ||
    history?.state?.state?.previousPath;

  const updateButtonRef = useRef(null);
  const selectedTextRef = useRef(null);
  const headingTextRef = useRef(null);
  const messageTextRef = useRef(null);
  const fontFamilyRef = useRef(null);
  const fontSizeRef = useRef(null);
  const fontColorRef = useRef(null);

  const color = [
    "#202020",
    "#ffffff",
    "#1F366C",
    "#EA5D5D",
    "#84AD63",
    "#6A0DAD",
    "#EAD151",
  ];

  const fontSize = [
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "22",
    "24",
    "26",
    "28",
    "30",
  ];
  const fontFamily = [
    "Helvetica",
    "Verdana",
    "Calibri",
    "Noto",
    "Lucida Sans",
    "Gill Sans",
    "Candara",
    "Futara",
    "Geneva",
    "Segoe UI",
    "Hop Light",
  ];

  const messages = [
    t("PersonlizeLabel.option1"),
    t("PersonlizeLabel.option2"),
    t("PersonlizeLabel.option3"),
    t("PersonlizeLabel.option4"),
    t("PersonlizeLabel.option5"),
    t("PersonlizeLabel.option6"),
    t("PersonlizeLabel.option7"),
    t("PersonlizeLabel.option8"),
    t("PersonlizeLabel.option9"),
    t("PersonlizeLabel.option10"),
    t("PersonlizeLabel.option11"),
    t("PersonlizeLabel.option12"),
  ];

  const [data, setData] = useState({
    title: "",
    fontSize: "20",
    messages: "",
    color: "#202020",
    fontFamily: "Hop Light",
  });

  const [images, setImages] = useState(mainImage);
  const [coordinate, setCoordinate] = useState({
    rotate: 0,
    x: 0,
    y: 0,
  });
  const [scale, setScale] = useState(true);
  const [selectedAction, setAction] = useState();
  const [layersState, setUpdateLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [warningVisible, setWarningVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [deleteImageVisible, setDeleteImageVisible] = React.useState(false);
  const [eventData, setEventData] = useState();
  const [update, setIsUpdate] = useState(false);
  const [errPopup, setErrPopup] = useState(false);
  const [deleteText, setDeleteText] = useState();
  const [loader, setLoader] = useState(false);
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (errPopup) {
      errorModal("The text is outside the image area");
      setErrPopup(false);
    }
  }, [errPopup]);

  useEffect(() => {
    getDeleteText();
  }, [deleteVisible]);

  useEffect(() => {
    layersState.map((item) => {
      if (item?.coordinates.x < 0 || item?.coordinates.y < 0) {
        setErrPopup(true);
      }
    });
  }, [layersState]);

  useEffect(() => {
    if (
      previousPath &&
      previousPath.includes("previewGift") &&
      getText !== undefined
    ) {
      setUpdateLayers([...getText]);
    }
  }, []);

  useEffect(() => {
    getDeleteText();
  }, []);

  useEffect(() => {
    getImageData();
  }, []);

  useEffect(() => {
    const _layersState = layersState.filter((e) => e.order === selectedLayer);
    if (
      selectedLayer !== undefined &&
      selectedLayer !== false &&
      selectedLayer !== null &&
      selectedLayer !== "removedSelectedLayer"
    ) {
      setData({
        ...data,
        title: _layersState[0].title,
        messages: _layersState[0].messages,
        fontSize: _layersState[0].fontSize,
        color: _layersState[0].color,
        fontFamily: _layersState[0].fontFamily,
      });
    } else {
      setData({
        ...data,
        title: "",
        messages: "",
        fontSize: "20",
        color: "#202020",
        fontFamily: "Hop Light",
      });
    }
    if (selectedLayer === "removedSelectedLayer") {
      setSelectedLayer(null);
      handleContine();
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (update) {
      selectedLayer && setSelectedLayer(null);
    }
  }, [update]);

  const handleContine = () => {
    dispatch(imageCoordinate({ coordinate: coordinate, scale: scale }));
    dispatch(setText(layersState));
    setLoader(true);
    if (![null, undefined, "removedSelectedLayer"].includes(selectedLayer)) {
      updateButtonRef.current.click();
      setSelectedLayer(() => "removedSelectedLayer");
      return;
    }
    var svgData = new XMLSerializer().serializeToString(svgRef.current);
    var canvas = document.createElement("canvas");
    canvas.width = 1200;
    canvas.height = 1200;
    var ctx = canvas.getContext("2d");
    var img = document.createElement("img");
    img.setAttribute(
      "src",
      "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)))
    );
    img.onload = function() {
      setTimeout(() => {
        ctx.drawImage(img, 0, 0, 1200, 1200);
      }, 2000);

      setTimeout(() => {
        setLoader(false);
        const image = canvas.toDataURL("image/png");
        dispatch(setCustomizeImageData({ image, svgData }));
        props.history.push({
          pathname: `/previewGift/${locationId}`,
          state: {
            image,
            svgData,
          },
        });
      }, 2000);
    };
  };

  const getImageData = () => {
    let res;
    let xhr = new XMLHttpRequest();
    xhr.open("GET", mainImage, true);
    xhr.responseType = "blob";
    xhr.onload = function(e) {
      console.log(this.response);
      let reader = new FileReader();
      reader.onload = function(event) {
        res = event.target.result;
        setImages(res);
      };
      let file = this.response;
      reader.readAsDataURL(file);
    };
    xhr.send();
  };

  const handleBack = () => {
    dispatch(cropImage({}));
    dispatch(setImage({}));
    props.history.push(`/selectImage/${locationId}`);
  };

  const handleRestart = () => {
    props.history.push(`/start/${locationId}`);
  };

  const handleSetLayerProperties = useCallback(
    (updatedLayerObj, order) => {
      let layerIndex = order;

      if (layerIndex === undefined) {
        layerIndex = selectedLayer;
      }

      if (layerIndex !== undefined) {
        const updatedLayers = [
          ...layersState.slice(0, layerIndex),
          updatedLayerObj,
          ...layersState.slice(layerIndex + 1),
        ];
        setUpdateLayers(updatedLayers);
      }
    },
    [layersState, selectedLayer]
  );

  const onRotate = () => {
    const newCood = {
      rotate: coordinate.rotate === -270 ? 0 : coordinate.rotate - 90,
      x: 0,
      y: 0,
    };
    const newCord = getNewCoordinate(newCood, scale);
    setCoordinate(newCord);
  };
  useEffect(() => {
    if (getImageCoordinate) {
      const obj = {
        rotate: getImageCoordinate?.coordinate.rotate,
        x: getImageCoordinate?.coordinate?.x,
        y: getImageCoordinate?.coordinate?.y,
      };
      setCoordinate(obj);
      setScale(getImageCoordinate?.scale);
    }
  }, []);

  const imageDelete = () => {
    setImages("");
    props.history.push(`/selectImage/${locationId}`);
  };

  const showModal = () => {
    setIsModalVisible(true);
    // setImages(item);
  };

  const updateCoordinates = (state, rectSize, newX, newY, isNewRect = true) => {
    const {
      minXLimitCheck,
      maxXLimitCheck,
      minYLimitCheck,
      maxYLimitCheck,
    } = checkBoxPosition(state, rectSize, true, isNewRect);
    const {
      coordinates: { x, y },
      width,
      height,
      rotate,
    } = state;

    const newCoordinates = { ...{ x, y } };

    const designWidth = width;
    const designHeight = height;

    if (rotate === 0) {
      if (minXLimitCheck) {
        newCoordinates.x = rectSize.x;
      } else if (maxXLimitCheck) {
        newCoordinates.x = rectSize.x + rectSize.width - designWidth;
      }

      if (minYLimitCheck) {
        newCoordinates.y = rectSize.y;
      } else if (maxYLimitCheck) {
        newCoordinates.y = rectSize.y + rectSize.height - designHeight;
      }
    } else {
      const designMaxX = x + designWidth;
      const designMaxY = y + designHeight;
      const leftTop = { x, y };
      const rightTop = { x: designMaxX, y };
      const leftBottom = { x, y: designMaxY };
      const rightBottom = { x: designMaxX, y: designMaxY };
      const xDistance =
        Math.min(leftTop.x, rightTop.x, leftBottom.x, rightBottom.x) - x;
      const yDistance =
        y - Math.min(leftTop.y, rightTop.y, leftBottom.y, rightBottom.y);
      newCoordinates.x = newX;
      newCoordinates.y = newY;

      if (minXLimitCheck) {
        newCoordinates.x = rectSize.x - xDistance;
      } else if (maxXLimitCheck) {
        newCoordinates.x =
          rectSize.x + rectSize.width - designWidth + xDistance;
      }

      if (minYLimitCheck) {
        newCoordinates.y = rectSize.y + yDistance;
      } else if (maxYLimitCheck) {
        newCoordinates.y =
          rectSize.y + rectSize.height - designHeight - yDistance;
      }
    }
    return newCoordinates;
  };

  const deleteTextModal = (e) => {
    setDeleteVisible(true);
  };

  const deleteImageModal = () => {
    setDeleteImageVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setWarningVisible(false);
    setDeleteVisible(false);
    setDeleteImageVisible(false);
  };

  // const handleKeyPress = (event) => {
  //   const changedValue = `${data.title}${event.key}`;
  //   const isDotsExits = changedValue.includes("...");
  //   if (isDotsExits) return;
  //   setData({ ...data, title: changedValue });
  // };

  // const handleKeyDown = (event) => {
  //   if (data.title.length && event.key === "Backspace") {
  //     const changedValue = data.title.slice(0, -1);
  //     setData({ ...data, title: changedValue });
  //   }
  // };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "messages") {
      setData({ ...data, title: value, [name]: value });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  const onFlip = () => {
    const newScale = !scale;
    setScale(newScale);
    const newCord = getNewCoordinate(coordinate, newScale);
    setCoordinate(newCord);
  };

  const getNewCoordinate = (oldCoordinate, newScale) => {
    const newCord = oldCoordinate;
    if (newCord.rotate === -270) {
      newCord.x = 1200;
      newCord.y = !newScale ? 1200 : 0;
    } else if (newCord.rotate === -90) {
      newCord.x = 0;
      newCord.y = !newScale ? 0 : 1200;
    } else if (newCord.rotate === -180) {
      newCord.x = !newScale ? 0 : 1200;
      newCord.y = 1200;
    } else {
      newCord.x = !newScale ? 1200 : 0;
      newCord.y = 0;
    }
    return newCord;
  };

  const checkBoxPosition = (selectedItemProperty, newRect) => {
    const {
      coordinates: { x, y },
      width,
      height,
    } = selectedItemProperty;
    const designWidth = width;
    const designHeight = height;
    const designMaxX = x + designWidth;
    const designMaxY = y + designHeight;

    const leftTop = { x, y };
    const rightTop = { x: designMaxX, y };
    const leftBottom = { x, y: designMaxY };
    const rightBottom = { x: designMaxX, y: designMaxY };
    const rotatedCoordinateValues = [
      leftTop,
      rightTop,
      leftBottom,
      rightBottom,
    ];

    const sideRect = newRect;

    const minXLimit = sideRect.x;
    const maxXLimit = sideRect.x + sideRect.width;
    const minYLimit = sideRect.y;
    const maxYLimit = sideRect.y + sideRect.height;
    const minXLimitCheck = rotatedCoordinateValues.some(
      (val) => minXLimit > val.x
    );
    const maxXLimitCheck = rotatedCoordinateValues.some(
      (val) => maxXLimit < val.x
    );
    const minYLimitCheck = rotatedCoordinateValues.some(
      (val) => minYLimit > val.y
    );
    const maxYLimitCheck = rotatedCoordinateValues.some(
      (val) => maxYLimit < val.y
    );

    return {
      minXLimitCheck,
      maxXLimitCheck,
      minYLimitCheck,
      maxYLimitCheck,
    };
  };

  const handleMove = (e) => {
    const mousePosition = getMousePosition(e, svgRef);
    const { offset } = layersState[selectedLayer];
    const newX = parseFloat(mousePosition.x - offset.x);
    const newY = parseFloat(mousePosition.y - offset.y);
    const newLayerState = { ...layersState[selectedLayer] };
    newLayerState.coordinates.x = newX;
    newLayerState.coordinates.y = newY;
    const {
      minXLimitCheck,
      maxXLimitCheck,
      minYLimitCheck,
      maxYLimitCheck,
    } = checkBoxPosition(newLayerState, rect);
    if (minXLimitCheck || maxXLimitCheck || minYLimitCheck || maxYLimitCheck) {
      const tempLayers = [...layersState];
      let newCordinates = tempLayers[selectedLayer].coordinates;
      if (
        !(
          (minXLimitCheck && newX < layersState[selectedLayer].coordinates.x) ||
          (maxXLimitCheck && newX > layersState[selectedLayer].coordinates.x) ||
          (minYLimitCheck && newY < layersState[selectedLayer].coordinates.y) ||
          (maxYLimitCheck && newY > layersState[selectedLayer].coordinates.y)
        )
      ) {
        newCordinates = updateCoordinates(
          { ...tempLayers[selectedLayer] },
          rect,
          newX,
          newY
        );
      }
      handleSetLayerProperties({
        ...layersState[selectedLayer],
        coordinates: newCordinates,
      });
    } else {
      const newCoordinates = {
        x: newX,
        y: newY,
      };

      handleSetLayerProperties({
        ...layersState[selectedLayer],
        coordinates: newCoordinates,
      });
      // validatePosition();
    }

    return false;
  };

  const handleAction = (e) => {
    if (selectedAction === "move") {
      handleMove(e);
    }
  };

  const handleText = () => {
    try {
      const filter = new Filter();
      const box = getTextSizeForStyle(
        data.title,
        data.fontSize * 4,
        "normal",
        "start",
        data.fontFamily
      );
      let defaultWidth = box.width + 23;
      let defaultHeight = box.height;
      let textSize = data.fontSize * 4;

      const halfWidth = printAreaState.top.x - printAreaState.left.x;
      const yOffset = (rect.height - defaultHeight) / 2;

      const tempRectCenter = getCenterCoordinates(
        { x: rect.x, y: rect.y },
        rect.width,
        rect.height,
        1
      );

      const newinitialCoordinates = {
        x: tempRectCenter.x - defaultWidth / 2,
        y: tempRectCenter.y - defaultHeight / 2,
      };

      const newArr = [...layersState];
      if (!data.title) return;
      const text = filter?.clean(data?.title);
      newArr.push({
        order: layersState.length,
        title: text,
        messages: data.messages,
        fontSize: data.fontSize,
        color: data.color,
        fontFamily: data.fontFamily,
        topleft_x: rect.x + halfWidth,
        topleft_y: rect.y + yOffset,
        width: defaultWidth,
        height: defaultHeight,
        text_height: textSize,
        coordinates: newinitialCoordinates,
        center: tempRectCenter,
        offset: { x: 169.73049926757812, y: 37.34967041015625 },
        ref: createRef(),
      });
      if (!text?.includes("*")) {
        setUpdateLayers(newArr);
      }

      if (text?.includes("*")) {
        errorModal("Detect potential adult and racy content");
      }
      // dispatch(setText(newArr));
      setData({
        ...data,
        title: "",
        messages: "",
        fontSize: "20",
        color: "#202020",
        fontFamily: "Hoplight",
      });
    } catch (e) {
      errorModal("Detect potential adult and racy content");
    }
  };
  const handleDelete = () => {
    const newLayers = layersState.filter(
      (layer) => layer.order !== selectedLayer
    );
    const updatedLayers = newLayers.map((layer, index) => {
      // eslint-disable-next-line no-param-reassign
      layer.order = index;
      return layer;
    });
    setUpdateLayers(updatedLayers);
  };
  const click = (event) => {
    const unnecessaryElement = [
      selectedTextRef,
      headingTextRef,
      messageTextRef,
      fontFamilyRef,
      fontSizeRef,
      fontColorRef,
      updateButtonRef,
    ];
    if (
      !(event?.path || event?.composedPath?.())?.some((e) =>
        unnecessaryElement.some(
          (element) =>
            element?.current === e ||
            Array.from(
              document.getElementsByClassName("ant-select-dropdown")
            )?.some((element) => element === e)
        )
      )
    ) {
      clickedOutsideLayer();
      window.removeEventListener("click", click);
    }
  };

  const clickedOutsideLayer = (e, chkCondition) => {
    setSelectedLayer(null);
    setIsUpdate(!update);
  };

  const moveble = (e) => {
    if (!update) {
      setAction("move");
    }
  };

  const handleSetAction = (e) => (order, action, ref) => {
    setEventData(e);
    if (e.preventDefault) {
      e.preventDefault();
    }

    setSelectedLayer(order);
    setAction(action);

    if (action === "delete") {
      handleDelete();
    } else {
      window.addEventListener("click", click);
      const mousePosition = e && getMousePosition(e, svgRef);
      const { coordinates, scale, design } = layersState[order];
      const designHeight = ref
        ? ref?.current?.getBBox().height
        : ref?.current?.getBBox().height;
      const dynamicWidth = ref
        ? ref?.current?.getBBox().width
        : ref?.current?.getBBox().width;
      if (mousePosition) {
        handleSetLayerProperties(
          {
            ...layersState[order],
            offset: {
              x: mousePosition.x - coordinates.x,
              y: mousePosition.y - coordinates.y,
            },
            center: getCenterCoordinates(
              coordinates,
              dynamicWidth,
              // designHeight,
              scale
            ),
            // ref,
            width: dynamicWidth,
            height: designHeight,
          },
          order
        );
      }
    }
  };

  // #region Work Area

  const getDeleteText = () => {
    if (layersState?.length > 0) {
      layersState?.map((layer, index) => setDeleteText(layer));
    }
  };

  const renderWorkArea = (e) => {
    if (layersState?.length > 0) {
      return layersState?.map((layer, index) => {
        const tempSelectedLayer = selectedLayer;
        const key = index;
        return (
          <g
            ref={tempSelectedLayer === layer.order ? selectedTextRef : null}
            key={key}
          >
            <Layers
              setErrPopup={setErrPopup}
              errPopup={errPopup}
              properties={layer}
              selected={tempSelectedLayer === layer.order}
              setAction={handleSetAction}
              rect={rect}
              data={layersState}
              rectCenter={rectCenter}
              order={index || 0}
              ref={layer?.ref || {}}
              printAreaState={printAreaState}
              showModal={deleteTextModal}
            />
          </g>
        );
      });
    }
  };

  // #endregion

  return (
    <>
      {loader && <Loading />}
      {isModalVisible && (
        <CropModel
          handleCancel={handleCancel}
          handleOk={handleOk}
          isModalVisible={isModalVisible}
          image={images}
          setIsModalVisible={setIsModalVisible}
          setImage={setImages}
          zoom={zoom}
          setZoom={setZoom}
        />
      )}
      <WarningModel
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={warningVisible}
      />
      <DeleteTextModal
        deleteText={deleteText}
        eventData={eventData}
        handleCancel={handleCancel}
        handleOk={handleSetAction}
        isModalVisible={deleteVisible}
      />
      <DeleteImageModal
        handleCancel={handleCancel}
        handleOk={imageDelete}
        isModalVisible={deleteImageVisible}
      />
      <BackgroundImage>
        <div className="d-flex justify-content-between">
          <Button className="left-arrow" onClick={handleBack}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={arrowLeft} alt="" />
              <span className="home-back-text">{t("common.back")}</span>
            </div>
          </Button>
          <Button className="right-restart" onClick={handleRestart}>
            <div>
              <span className="home-restart-text">{t("common.restart")}</span>
              <img src={restart} alt="" />
            </div>
          </Button>
        </div>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div className="personalize">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
                  <div className="personalize-title text-center">
                    {t("PersonlizeLabel.title")}
                  </div>
                </div>
              </div>
              <div className="personalize-center d-flex justify-content-between p-4">
                <div className="personalize-left w-100 d-flex justify-content-center h-90 flex-column align-items-center">
                  <div className="personalize-left-up">
                    <svg
                      id="mainSvg"
                      className="Workspace-platform-nUV03"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1200 1200"
                      height="1200"
                      ref={svgRef}
                      onDrag={handleAction}
                      fill="transparent"
                      onTouchMove={(e) => handleAction(e.touches[0])}
                      onMouseLeave={() => {
                        setAction(null);
                      }}
                      onMouseUp={(e) => moveble(e)}
                      style={{ width: "auto", height: "375px" }}
                    >
                      <g
                        style={{
                          transform: `translate(${coordinate.x}px,${
                            coordinate.y
                          }px) rotate(${coordinate.rotate}deg) scaleX(${
                            scale ? 1 : -1
                          })`,
                        }}
                      >
                        <svg
                          className="Workspace-platform-nUV03"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 1200 1200"
                          width="1200"
                          height="1200"
                        >
                          <image
                            className="Workspace-productImage-1V1gC"
                            xlinkHref={images}
                            x="0"
                            y="0"
                            width="1200"
                            height="1200"
                          />
                        </svg>
                      </g>
                      <rect
                        className="rectArea"
                        x={rect.x}
                        y={rect.y}
                        width={rect.width}
                        height={rect.height}
                        onTouchStart={() => {
                          // clickedOutsideLayer();
                        }}
                      />
                      {renderWorkArea()}
                    </svg>
                  </div>
                  <div id={"test123"} />
                  <div className="personalize-left-down d-flex mt-4">
                    <div className="pesonalize-action">
                      <button onClick={showModal}>
                        <img src={CropImage} alt="" />
                      </button>
                    </div>
                    <div className="pesonalize-action">
                      <button onClick={onRotate}>
                        <img src={RotateImage} alt="" />
                      </button>
                    </div>
                    <div className="pesonalize-action">
                      <button onClick={onFlip}>
                        <img src={FlipImage} alt="" />
                      </button>
                    </div>
                    <div
                      className="pesonalize-action d-flex justify-content-center align-items-center"
                      onClick={deleteImageModal}
                    >
                      <img src={DeleteImage} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="personalize-right d-flex justify-content-between flex-column w-100"
                  ref={headingTextRef}
                >
                  <Input
                    name="title"
                    placeholder={t("PersonlizeLabel.placeholderTitle")}
                    value={data.title}
                    onChange={handleChange}
                    // onKeyPress={handleKeyPress}
                    // onKeyDown={handleKeyDown}
                  />
                  <div ref={messageTextRef} style={{ marginTop: "10px" }}>
                    <Select
                      className="select-menu"
                      placeholder={t("PersonlizeLabel.placeholderMesasages")}
                      style={{ width: "100%" }}
                      name="messages"
                      id="message"
                      value={data.messages}
                      onChange={(event) =>
                        handleChange({
                          target: {
                            name: "messages",
                            value: event,
                          },
                        })
                      }
                    >
                      <Option value={""} hidden>
                        {t("PersonlizeLabel.placeholderMesasages")}
                      </Option>
                      {messages.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div ref={fontFamilyRef} style={{ marginTop: "5px" }}>
                    <Select
                      defaultValue="Helvetica"
                      className="select-menu"
                      placeholder={t("PersonlizeLabel.placeholderFontFamily")}
                      style={{ width: "100%" }}
                      name="fontFamily"
                      id="fontFamily"
                      value={data.fontFamily}
                      onChange={(event) =>
                        handleChange({
                          target: {
                            name: "fontFamily",
                            value: event,
                          },
                        })
                      }
                    >
                      {fontFamily.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="row" style={{ marginTop: "5px" }}>
                    <div className="col-md-6 font-select" ref={fontSizeRef}>
                      <Select
                        className="select-menu"
                        style={{ width: "230.85px" }}
                        name="fontSize"
                        id="fontSize"
                        placeholder={t("PersonlizeLabel.pleaceholderFontSize")}
                        value={data.fontSize}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "fontSize",
                              value: event,
                            },
                          })
                        }
                      >
                        {fontSize.map((item, index) => (
                          <Option
                            key={index}
                            className="fontOption"
                            value={item}
                          >
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-md-6" ref={fontColorRef}>
                      <Select
                        className="select-menu"
                        style={{ width: "230.85px" }}
                        name="color"
                        id="color"
                        value={data.color}
                        placeholder={t("PersonlizeLabel.placeholderColor")}
                        onChange={(event) => {
                          handleChange({
                            target: {
                              name: "color",
                              value: event,
                            },
                          });
                        }}
                      >
                        {color.map((item, index) => (
                          <Option key={index} value={item}>
                            <div className={`item-option-${index}`}>{item}</div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mt-2 mb-2">
                    <div className="col-md-12 col-sm-6 d-flex justify-content-end mt-2">
                      {selectedLayer !== undefined &&
                      selectedLayer !== false &&
                      selectedLayer !== null ? (
                        <button
                          ref={updateButtonRef}
                          className="buttonCustom add-text"
                          onClick={() => {
                            const _layersState = [...layersState];
                            _layersState[selectedLayer] = {
                              ..._layersState[selectedLayer],
                              ...data,
                            };
                            setUpdateLayers(_layersState);
                            dispatch(
                              updateText({
                                data: data,
                                selectedLayer: selectedLayer,
                              })
                            );
                            clickedOutsideLayer();
                          }}
                        >
                          {t("common.update")}
                        </button>
                      ) : (
                        <button
                          onClick={handleText}
                          className="buttonCustom add-text"
                        >
                          {t("PersonlizeLabel.addText")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-6 d-flex justify-content-end mt-2">
                      <button className="buttonCustom" onClick={handleContine}>
                        {t("PersonlizeLabel.personalizeButton")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};

export default PersonalizeLabel;
