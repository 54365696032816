import {handleActions} from "redux-actions";
import {requestFail, requestPending, requestSuccess} from "../../../fetch";
import {initialProductState} from "./initialProductState";
import {productConstant} from "./productConstant";

export const productReducer = handleActions(
    {
        [requestSuccess(productConstant.GET_ALL_PRODUCT)]: (
            state,
            action
        ) => {
            return {
              ...state,
              productDetails: action.payload || {},
              getAllProductLoading: false,
              getAllProductFailure: false,
              getAllProductLoaded: true,
            };
        },
        [requestPending(productConstant.GET_ALL_PRODUCT)]: (state) => ({
            ...state,
            getAllProductLoading: true,
            getAllProductFailure: false,
            getAllProductLoaded: false,
        }),
        [requestFail(productConstant.GET_ALL_PRODUCT)]: (state) => ({
            ...state,
            getAllProductLoading: false,
            getAllProductFailure: true,
            getAllProductLoaded: true,
        }),
    },
    initialProductState()
);
