import React, { useEffect, useState } from "react";
import leftLogo from "../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../assets/images/modal-bg-right.png";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import "./index.scss";
import { useTranslation } from "react-i18next";

const TimeModal = ({
  handleCancel,
  handleOk,
  isModalVisible,
  redirectStartPage,
}) => {
  const {t} = useTranslation();
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  let [time, setTime] = useState(30);
  let myInterval = setTimeout(() => {
    if (time > 0) {
      setTime(time - 1);
    } else {
       window.location.href = `/start/${locationId}`;
    }
  }, 1000);

  return (
    <>
      <Modal
        className="start-model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="model-left-logo position-absolute" style={{backgroundColor:"#FFFCF5", borderRadius:"10px"}}>
          <img src={leftLogo} alt="" width={200} height={125} />
        </div>
        <div className="model-right-logo position-absolute">
          <img src={rightLogo} alt="" width={200} height={125} />
        </div>
        <div className="start-timer">
          <div className="circular">
            <div className="inner"></div>
            <div className="number">{time}</div>
            <div className="circle">
              <div className="bar left">
                <div
                  className="progress"
                  style={{
                    backgroundColor: time >= 15 ? "#BA4E4E" : "#EDE1C5",
                  }}
                ></div>
              </div>
              <div className="bar right">
                <div className="progress"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <div className="modal-text">{t("TimeModal.text")}</div>
          <button className="buttonCustom timeButton mt-5" onClick={handleOk}>
            YES
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TimeModal;
