import React from "react";
import { useSelector } from "react-redux";
import LeftImage from "../../../assets/images/startTin.png";
import RightImage from "../../../assets/images/startBag.png";
import Logo from "../../../assets/images/logo.svg";
import "./index.scss";

const MainBox = ({
  children,
  leftImage = LeftImage,
  rightImage = RightImage,
  showSideImage = false,
}) => {
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  return (
    <div
      className={`${
        window.location.pathname === `/basicInfo/${locationId}`
          ? "basic-info-box"
          : "box"
      }`}
    >
      <div>{children}</div>
      <div
        className={`${
          window.location.pathname === `/start/${locationId}`
            ? "Start-logo"
            : "logo"
        } ${window.location.pathname !==
          `/start/${isNaN(parseInt(locationId))}` &&
          isNaN(parseInt(locationId)) &&
          "Offline-logo"}`}
      >
        <img src={Logo} alt={``} width={250} height={94.89} />
      </div>
      {showSideImage && (
        <div className={`sideImageBox left-Image`}>
          <img
            src={leftImage}
            alt={``}
            width={250}
            height={179.98}
            className="position-absolute"
          />
        </div>
      )}
      {showSideImage && (
        <div className={`sideImageBox right-image`}>
          <img src={rightImage} alt={``} width={250} height={380.94} />
        </div>
      )}
    </div>
  );
};
export default MainBox;
