import {SET_IMAGE, SET_SELECTED_IMAGES_DATA, SET_CUSTOMIZE_IMAGES_DATA, SET_SELECTED_IMAGES, SET_TEXT, UPDATE_TEXT, CLEAR_STORE, CROP_IMAGE, IMAGE_COORDINATE} from "./const";

export const setImage = (data) => ({
    type: SET_IMAGE,
    payload: data
});

export const setSelectedImageData = (data) => ({
    type: SET_SELECTED_IMAGES_DATA,
    payload: data
})

export const setCustomizeImageData = (data) => ({
    type: SET_CUSTOMIZE_IMAGES_DATA,
    payload: data
})

export const setSelectedImages = (data) => ({
  type: SET_SELECTED_IMAGES,
  payload: data,
});

export const setText = (data) => ({
    type : SET_TEXT,
    payload : data,
})

export const updateText = (data) => ({
    type : UPDATE_TEXT,
    payload : data,
})

export const cropImage = (data) => ({
    type : CROP_IMAGE,
    payload : data,
})

export const imageCoordinate = (data) =>({
   type : IMAGE_COORDINATE,
   payload : data,
})