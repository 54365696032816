import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectedLug } from "../../../Main";
import { kioskTranslate } from "../../../../utils/redux/modules/Kiosk/kioskAction";
import { useDispatch } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-7.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import "./index.scss";

const DeliveryModel = ({ handleCancel, handleOk, isModalVisible, error }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Language } = useContext(SelectedLug);

  const Data = Object.values(error?.data?.errors);

  const [ErrData, setErrData] = useState();
  const [trasData, setTrasData] = useState();

  useEffect(() => {
    errData();
    if (Language == "es") {
      KisoskTranslate();
    }
  }, [ErrData]);

  const KisoskTranslate = () => {
    const onFail = (err) => {
      const { data } = err;
      errorMessage(data ? data.title : "Something went wrong!");
    };
    const onSuccess = (res) => {
      setTrasData(res);
    };

    dispatch(
      kioskTranslate({
        data: {
          text: ErrData?.toString(),
          fromLangCode: "en",
          toLangCode: "es",
        },
        onFail,
        onSuccess,
      })
    );
  };

  const errData = () => {
    Data?.length > 0 && Data.map((item) => setErrData(item.toString()));
  };
  return (
    <>
      <div>
        <Modal
          className="delivery-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={200} height={120} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={200} height={120} />
          </div>
          <div className="delivery-logo">
            <img src={logo} alt="" width={100} height={94} />
          </div>
          <div className="delivery-model-main d-flex justify-content-center flex-column align-items-center h-100">
            <div className="delivery-logo"></div>
            <div className="delivery-model-title">{t("common.opps")}</div>
            <div className="delivery-model-details">{trasData ? trasData : ErrData }</div>
            <div className="delivery-model-botton">
              <button className="buttonCustom mt-3" onClick={handleCancel}>
                {t("common.gotit")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeliveryModel;
