import React from "react";
// import BackgroundImage1 from "../../../assets/images/backgroundImage_1.jpg"
import BackgroundImage1 from "../../../assets/images/newbackground.jpg";

import "./index.scss";

const BackgroundImage = ({ children, imageSrc = BackgroundImage1 }) => {
  return (
    <div
      className={`backgroundContent w-100 h-100`}
      style={{ overflow: "hidden" }}
    >
      <div className={`backgroundImage`}>
        <img src={imageSrc} alt={``} width={`100%`} height={`100%`} />
      </div>
      <div className={`background-image`}>{children}</div>
    </div>
  );
};

export default BackgroundImage;
