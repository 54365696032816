import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import { useSelector } from "react-redux";
import { SelectedLug } from "../Main";
import "./index.scss";



const StartPage = (props) => {
  const { setLanguage } = useContext(SelectedLug);
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const { i18n } = useTranslation();

  const onRedirect = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng)
    props.history.push(`/SelectProductType/${locationId}`);
  };
  
  return (
    <>
      <BackgroundImage>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={true}>
            <div
              className={`startBox d-flex flex-column align-items-center justify-content-center `}
            >
              <p className={`text`} align={`center`}>
                Make Your Own Personalized Gift
              </p>

              <div className="start-button d-flex">
                <button
                  className={`buttonCustom english-button`}
                  onClick={() => onRedirect("en")}
                >
                  English
                </button>
                <button
                  className={`buttonCustom ml-5 espanol-button`}
                  onClick={() => onRedirect("es")}
                >
                  ESPAÑOL
                </button>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};

export default StartPage;
