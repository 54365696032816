import { takeLatest, call } from "redux-saga/effects";
import { LoginConstant } from "./loginConstant";
import { request } from "../../../fetch";
import { baseURL } from "../../../apiConsts";

function* fetchLogin(action) {
  const data = yield call(
    request({
      type: LoginConstant.CHECK_LOGIN,
      method: "POST",
      url: "Login/login",
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
      baseURL: baseURL,
      data: action.payload.data,
    }),
    action
  );
  localStorage.setItem("access_token", data.token);
}

export default function* rootSaga() {
  yield takeLatest(LoginConstant.CHECK_LOGIN, fetchLogin);
}
