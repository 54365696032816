import React from "react";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-7.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import "./index.scss";

const WarningModel = ({ handleCancel, handleOk, isModalVisible }) => {
  return (
    <>
      <div>
        <Modal
          className="warning-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={250} height={150} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={250} height={150} />
          </div>
          <div className="delivery-logo">
            <img src={logo} alt="" width={180} height={168} />
          </div>
          <div className="delivery-model-main d-flex justify-content-center flex-column align-items-center h-100">
            <div className="delivery-logo"></div>
            <div className="delivery-model-title">OOPS!</div>
            <div className="delivery-model-details mt-4 mb-4">
              Some of your content violates our guidelines. Please edit all
              fields in red.
            </div>
            <div className="delivery-model-botton">
              <button className="buttonCustom mt-3" onClick={handleCancel}>
                GOT IT
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default WarningModel;
