import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../common/components/Button/Button";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import Bag from "../../assets/images/bag.png";
import arrowLeft from "../../assets/images/arrow-left.svg";
import restart from "../../assets/images/restart.svg";
import TimeModal from "../../common/components/TimeModal";
import "./index.scss";

const PreviewGift = (props) => {
  const { t } = useTranslation();
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const selectedImageData = useSelector(
    (state) => state.imageData.setSelectedImageData.image
  );
  const selectedCustomizeData = useSelector(
    (state) => state.imageData.setCustomizeImageData
  );

  const handleBack = () => {
    props.history.push({
      pathname: `/personalizeLabel/${locationId}`,
      state: {
        selectedCustomizeData,
        previousPath: window.location.pathname,
      },
    });
  };

  const handleSubmit = () => {
    props.history.push(`/personalInfo/${locationId}`);
  };

  const handleRestart = () => {
    props.history.push({ pathname: `/start/${locationId}` });
  };
  return (
    <>
      <BackgroundImage>
        <div className="d-flex justify-content-between">
          <Button className="left-arrow" onClick={handleBack}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={arrowLeft} alt="" />
              <span className="home-back-text">{t("common.back")}</span>
            </div>
          </Button>
          <Button className="right-restart" onClick={handleRestart}>
            <div>
              <span className="home-restart-text">{t("common.restart")}</span>
              <img src={restart} alt="" />
            </div>
          </Button>
        </div>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div className="previewGift d-flex justify-content-center flex-column align-items-center">
              <div className="previewGift-title">{t("Preview.title")}</div>
              <div
                className={`${
                  selectedImageData === "/static/media/bag.c049303c.png"
                    ? "previewGift-image"
                    : "previewGift-image-one"
                }`}
              >
                <img
                  className={`${selectedImageData ===
                    "/static/media/tin.58204810.png" && "TinImage"}`}
                  src={selectedImageData}
                  height={`${
                    selectedImageData === "/static/media/tin.58204810.png"
                      ? "290px"
                      : "325px"
                  }`}
                />
                <div
                  className={`${
                    selectedImageData === "/static/media/bag.c049303c.png"
                      ? "previewGift-SubImage"
                      : "previewGift-SubImage-tin"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: selectedCustomizeData.svgData,
                  }}
                />
              </div>
              <div
                className={`previewGift-button d-flex justify-content-center ${
                  selectedImageData === "/static/media/bag.c049303c.png"
                    ? "mt-5"
                    : "mb-4"
                }`}
              >
                <button className="buttonCustom" onClick={handleSubmit}>
                  {t("Preview.previewButton")}
                </button>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};

export default PreviewGift;
