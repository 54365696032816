import React, { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import { config } from "../../utils/config";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setImage } from "../../utils/redux/modules/imageHandler/action";
import { setSelectedImages } from "../../utils/redux/modules/imageHandler/action";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import ImageModel from "./component/model";
import ImageOne from "../../assets/images/GhirardelliSquare.jpg";
import ImageTwo from "../../assets/images/NHFSD.jpg";
import ImageThree from "../../assets/images/CaramelBar.jpg";
import ImageFour from "../../assets/images/CaramelFamily.jpg";
import ImageFive from "../../assets/images/labelimg4.jpg";
import ImageSix from "../../assets/images/IntenseDark.jpg";
import ImageSeven from "../../assets/images/Squares 2.png";
import ImageEight from "../../assets/images/Baking.jpg";
import ImageNine from "../../assets/images/VDay.jpg";
import ImageTen from "../../assets/images/Easter.jpg";
import ImageEleven from "../../assets/images/Xmas1.jpg";
import ImageTwelve from "../../assets/images/Xmas2.jpg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import restart from "../../assets/images/restart.svg";
import Button from "../../common/components/Button/Button";
import TimeModal from "../../common/components/TimeModal";
import "./selectImage.scss";

const uniqueSID =
  Math.random()
    .toString(36)
    .substring(2, 15) +
  Math.random()
    .toString(36)
    .substring(2, 15);

const SelectImage = (props) => {
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const dispatch = useDispatch();
  const selectedImageData = useSelector(
    (state) => state.imageData.setSelectedImageData.image
  );
  const [connection, setConnection] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [product, setProduct] = useState("");
  const [responce, setResponce] = useState();
  const [selectedImage, setSeletedImage] = useState({
    imageName: "/static/media/Baking.9ebad3b8.jpg",
    index: "0",
  });

  useEffect(() => {
    if (props?.location?.state) {
      setProduct(props.location.state.image);
    }
  }, []);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(config.CONNECTION_HUB)
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("CustomImageHandler", (KisokId, screenId, image) => {
            console.log("CustomImageHandler", { KisokId, screenId, image });
            if (uniqueSID === screenId) {
              setResponce({ KisokId, screenId, image });
              dispatch(setImage({ KisokId, screenId, image }));
              props.history.push(`/personalizeLabel/${locationId}`);
            }
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsModalVisible(false);
    }, 120000);
  }, [isModalVisible]);

  const handleBack = () => {
    props.history.push(`/selectProductType/${locationId}`);
  };
  const redirectStartPage = () => {
    props.history.push(`/start/${locationId}`);
  };
  const handleRestart = () => {
    props.history.push({ pathname: `/start/${locationId}` });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { t } = useTranslation();
  const setSelectedImage = (imageName, idx) => {
    setSeletedImage({ imageName: imageName, index: idx });
  };

  const handleSubmit = () => {
    props.history.push(`/personalizeLabel/${locationId}`);
    dispatch(setSelectedImages({ selectedImage }));
  };
  const imageArray = [
    ImageOne,
    ImageTwo,
    ImageThree,
    ImageFour,
    ImageFive,
    ImageSix,
    ImageSeven,
    ImageEight,
    ImageNine,
    ImageTen,
    ImageEleven,
    ImageTwelve,
  ];

  return (
    <>
      <ImageModel
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        kisokId={1}
        screenId={uniqueSID}
      />
      <BackgroundImage>
        <div className="d-flex justify-content-between">
          <Button className="left-arrow" onClick={handleBack}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={arrowLeft} alt="" />
              <span className="home-back-text">{t("common.back")}</span>
            </div>
          </Button>
          <Button className="right-restart" onClick={handleRestart}>
            <div>
              <span className="home-restart-text">{t("common.restart")}</span>
              <img src={restart} alt="" />
            </div>
          </Button>
        </div>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div className="selectImage d-flex justify-content-between flex-column">
              <div className="selectImage-top">
                <div className="selectImage-top-text">
                  {t("selectImage.title")}
                </div>
              </div>
              <div className="selectImage-center d-flex align-items-center justify-content-between">
                <div
                  className={`${
                    selectedImageData === "/static/media/bag.c049303c.png"
                      ? "selectImage-center-Bag"
                      : "selectImage-center-Tin"
                  } d-flex justify-content-center`}
                  style={{ width: "42%" }}
                >
                  {selectedImageData === "/static/media/bag.c049303c.png" ? (
                    <div className="position-relative">
                      <img src={selectedImageData} height={"355px"} />
                      {selectedImage?.imageName !== "" && (
                        <img
                          className="first-img"
                          src={selectedImage?.imageName}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="position-relative">
                      <img
                        src={selectedImageData}
                        width={288.91}
                        height={208}
                        className="imageTin"
                      />
                      {selectedImage?.imageName !== "" && (
                        <img
                          className="second-img"
                          src={selectedImage?.imageName}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="selectImage-center-right">
                  <div className="selectImage-center-right-main d-flex flex-wrap">
                    <div className="selectImage-center-frame">
                      <div className="selectImage-center-frame-one d-flex align-items-center justify-content-center">
                        <div
                          className="hover-main d-flex justify-content-center align-items-center"
                          onClick={showModal}
                        >
                          <label className="selectImage-center-frame-text">
                            {t("selectImage.fileUpload")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {imageArray?.map((i, idx) => (
                      <div key={idx} className="selectImage-center-frame">
                        <div className="selectImage-center-frame-two position-relative cursor-pointer">
                          <div style={{ height: "146px" }}>
                            <img
                              onClick={() => setSelectedImage(i, idx)}
                              src={i}
                              alt=""
                              height={`100%`}
                              width={`100%`}
                            />
                          </div>
                          {selectedImage?.index === idx && (
                            <span className="check-mark">
                              <CheckOutlined style={{ color: "#ffffff" }} />
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="selectImage-Down mt-3 mb-3 d-flex justify-content-start"
                    onClick={handleSubmit}
                  >
                    <button className="buttonCustom">
                      {t("selectImage.button")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};
export default SelectImage;
