import React from "react";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import Logo from '../../assets/images/offline-icon.png';
import "./index.scss";

const TemporarilyOffline = () => {
  return (
    <BackgroundImage>
      <div
        className={`d-flex w-100 h-100 align-items-center justify-content-center`}
      >
        <MainBox showSideImage={false}>
          <div className="temp">
            <div className="temp-main d-flex align-items-center flex-column justify-content-center h-100">
              <div className="temp-logo mt-26 mb-3">
                <img src={Logo} alt="" height={80} width={80} />
              </div>
              <div className="temp-title">Temporarily Offline</div>
              <div className="temp-details-main d-flex flex-column justify-content-center mt-3">
                <div className="temp-details mt-20 mb-20 text-center">
                  Sorry, this kiosk is currently offline. Please come back
                  later.
                </div>
                <div className="temp-details mt-1 text-center">
                  Lo sentimos, este quiosco está actualmente fuera de línea. Por
                  favor regresa más tarde.
                </div>
              </div>
              <button className="buttonCustom mt-3 mb-3" onClick={() => window.location.reload()}>REFRESH</button>
            </div>
          </div>
        </MainBox>
      </div>
    </BackgroundImage>
  );
};

export default TemporarilyOffline;
