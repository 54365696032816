import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Input } from "antd";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import DeliveryModel from "./components/model";
import TermsModel from "./components/TermsandConditions";
import PrivacyModel from "./components/PrivacyPolicy";
import ReturnModel from "./components/ReturnPolicy";
import arrowLeft from "../../assets/images/arrow-left.svg";
import restart from "../../assets/images/restart.svg";
import Button from "../../common/components/Button/Button";
import {
  fetchPersonalInfo,
  getProductVariant,
} from "../../utils/redux/modules/PersonalInfo/PersonalInfoAction";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage } from "../../utils/common";
import Loading from "../../common/components/Loading";
import "./index.scss";

const DeliveryForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state?.personalInfo?.fetchPersonalInfoLoading
  );
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const KioskData = useSelector((state) => state.kiosk.details);
  const selectedImageData = useSelector(
    (state) => state.imageData.setSelectedImageData.image
  );
  const productId = useSelector(
    (state) => state.imageData.setSelectedImageData.productId
  );
  const selectedCustomizeData = useSelector(
    (state) => state.imageData.setCustomizeImageData
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [TemsModel, setTermsModel] = useState(false);
  const [privacyModel, setPrivacyModel] = useState(false);
  const [returnModel, setReturnModel] = useState(false);
  const [product, setProduct] = useState();
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zip: "",
  });
  const [errors, setErrors] = useState({});
  const [checkBoxOne, setCheckBoxOne] = useState(false);
  const [checkBoxTwo, setCheckBoxTwo] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const termsShowModel = () => {
    setTermsModel(true);
  };
  const privacyShowModel = () => {
    setPrivacyModel(true);
  };
  const returnShoeModel = () => {
    setReturnModel(true);
  };
  const handleOk = () => {
    setTermsModel(false);
    setPrivacyModel(false);
    setReturnModel(false);
  };

  const errorCloseModal = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setTermsModel(false);
    setPrivacyModel(false);
    setReturnModel(false);
  };
  const handleBack = () => {
    props.history.push(`/previewGift/${locationId}`);
  };
  const redirectStartPage = () => {
    props.history.push(`/start/${locationId}`);
  };
  const handleRestart = () => {
    props.history.push(`/start/${locationId}`);
  };
  const handleInputs = (event) => {
    let { name, value } = event.target;
    if (name === "zip") {
      value = value.replace(/[^0-9\s-]/g, "");
    }
    if (name === "phone") {
      value = value.replace(/[^0-9\s-]/g, "");
    }
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = () => {
    const onFail = (err) => {
      const { data } = err;
      errorMessage(data ? data.title : "Something went wrong!");
    };
    const onSuccess = (res) => {
      setProduct(res[0]);
    };
    dispatch(
      getProductVariant({
        data: { id: productId },
        onFail,
        onSuccess,
      })
    );
  };

  const validation = (name, value) => {
    const emailRegx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phone = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    switch (name) {
      case "firstName":
        if (!value) {
          return t("validation.firstName");
        } else {
          return "";
        }
      case "lastName":
        if (!value) {
          return t("validation.lastName");
        } else {
          return "";
        }
      case "zip":
        if (value.length != 5) {
          return t("validation.zip");
        } else {
          return "";
        }
      case "email":
        if (form.email === "" && form.phone === "") {
          return t("validation.email&phone");
        } else {
          if (form.phone === "" && !emailRegx.test(value)) {
            return t("validation.email");
          } else {
            return "";
          }
        }
      case "phone":
        if (form.email === "" || form.phone === "") {
          if (form.phone && !phone.test(value)) {
            return t("validation.phone");
          } else {
            return "";
          }
        } else {
          if (!phone.test(value)) {
            return t("validation.phone");
          } else {
            return "";
          }
        }
      default:
        break;
    }
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const fetchInfo = () => {
    const file = DataURIToBlob(selectedCustomizeData.image);
    const formBody = new FormData();
    formBody.append("CustomerFirstName", form.firstName);
    formBody.append("CustomerLastName", form.lastName);
    formBody.append("CustomerEmail", form.email);
    formBody.append("CustomerPhone", '');
    // formBody.append("CustomerPhone", form.phone);
    formBody.append("Zip", form.zip);
    formBody.append("kioskId", KioskData.kioskId);
    formBody.append("brandId", KioskData.brandId);
    formBody.append("isAllowNewsletter", checkBoxTwo);
    formBody.append("isAcceptTC", checkBoxOne);
    formBody.append("productId", productId);
    formBody.append("StoreId", KioskData.storeId);
    formBody.append("productVariantId", product.productVariantId);
    formBody.append("Image", file, "image.png");

    const onFail = (err) => {
      if (err) {
        showModal();
      }
      setError(err);
    };
    const onSuccess = (e) => {
      props.history.push(`/success/${locationId}`);
      setForm({});
      setErrors({});
    };
    dispatch(
      fetchPersonalInfo({
        data: formBody,
        onFail,
        onSuccess,
      })
    );
  };

  const handleSubmit = () => {
    const Warn = {
      firstName: form.firstName,
      lastName: form.lastName,
      zip: form.zip,
      email: form.email,
      // phone: form.phone,
    };
    let allErrors = {};
    Object.keys(Warn).forEach((key) => {
      const error = validation(key, Warn[key]);
      if (error && error.length) {
        allErrors[key] = error;
      }
    });
    if (Object.keys(allErrors).length) {
      return setErrors(allErrors);
    } else {
      fetchInfo();
    }
  };

  const checkBoxOneOnchange = (event) => {
    setCheckBoxOne(event.target.checked);
  };
  const checkBoxTwoOnchange = (event) => {
    setCheckBoxTwo(event.target.checked);
  };

  return (
    <>
      {loading && <Loading />}
      {error !== "" && (
        <DeliveryModel
          error={error}
          handleCancel={handleCancel}
          handleOk={errorCloseModal}
          isModalVisible={isModalVisible}
        />
      )}
      <TermsModel
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={TemsModel}
      />
      <PrivacyModel
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={privacyModel}
      />
      <ReturnModel
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={returnModel}
      />

      <BackgroundImage>
        <div className="d-flex justify-content-between">
          <Button className="left-arrow" onClick={handleBack}>
            <div className="d-flex justify-content-center align-items-center">
              <img src={arrowLeft} alt="" />
              <span className="home-back-text">{t("common.back")}</span>
            </div>
          </Button>
          <Button className="right-restart" onClick={handleRestart}>
            <div>
              <span className="home-restart-text">{t("common.restart")}</span>
              <img src={restart} alt="" />
            </div>
          </Button>
        </div>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div className="delivery d-flex justify-content-center flex-column">
              <div className="delivery-main d-flex justify-content-between">
                <div className="delivery-main-left w-50">
                  <div
                    className={`${
                      selectedImageData === "/static/media/bag.c049303c.png"
                        ? "delivery-main-left-img d-flex align-items-center justify-content-center h-100"
                        : "delivery-main-left-img d-flex align-items-center  h-100"
                    }`}
                  >
                    <img
                      src={selectedImageData}
                      alt=""
                      className={`${
                        selectedImageData === "/static/media/bag.c049303c.png"
                          ? "personalGift-SubImage-Bag-Image"
                          : "personalGift-SubImage-Tin-Image"
                      }`}
                    />
                    <div
                      className={`${
                        selectedImageData === "/static/media/bag.c049303c.png"
                          ? "personalGift-SubImage"
                          : "personalGift-SubImage-tin"
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: selectedCustomizeData.svgData,
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    selectedImageData === "/static/media/bag.c049303c.png"
                      ? "delivery-main-margin w-100"
                      : "delivery-main-right w-100"
                  }`}
                >
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <Input
                        placeholder={t("PersonalInfo.placeholderFirstName")}
                        className="firstname"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleInputs}
                      />
                      <div className="validation">{errors.firstName}</div>
                    </div>
                    <div className="col-md-4">
                      <Input
                        placeholder={t("PersonalInfo.placeholderLastName")}
                        className="lastname"
                        name="lastName"
                        value={form.lastName}
                        onChange={handleInputs}
                      />
                      <div className="validation">{errors.lastName}</div>
                    </div>
                    <div className="col-md-4">
                      <Input
                        placeholder={t("PersonalInfo.placeholderZipCode")}
                        className="zipcode"
                        name="zip"
                        maxLength={5}
                        value={form.zip}
                        onChange={handleInputs}
                      />
                      <div className="validation">{errors.zip}</div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-5">
                      <Input
                        placeholder={t("PersonalInfo.placeholderEmail")}
                        className="email"
                        name="email"
                        value={form.email}
                        onChange={handleInputs}
                      />
                      <div className="validation">{errors.email}</div>
                    </div>
                    {/*<div className="or d-flex align-items-center">*/}
                    {/*  {t("PersonalInfo.textOr")}*/}
                    {/*</div>*/}
                    {/*<div className="col-md-5 p-0">*/}
                    {/*  <Input*/}
                    {/*    placeholder={t("PersonalInfo.placeholderPhone")}*/}
                    {/*    className="phone"*/}
                    {/*    name="phone"*/}
                    {/*    value={form.phone}*/}
                    {/*    maxLength={10}*/}
                    {/*    onChange={handleInputs}*/}
                    {/*  />*/}
                    {/*  <div className="validation">{errors.phone}</div>*/}
                    {/*</div>*/}
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12 d-flex align-items-center">
                      <Checkbox
                        className="CheckboxMenu"
                        onChange={checkBoxOneOnchange}
                      />
                      <label className="checkbox-lable col-md-11">
                        {t("PersonalInfo.text2")} &nbsp;
                        <a onClick={termsShowModel}>
                          {t("PersonalInfo.terms")}
                        </a>
                        , &nbsp;
                        <a onClick={privacyShowModel}>
                          {t("PersonalInfo.Privacy")}
                        </a>
                        ,&nbsp;&&nbsp;&nbsp;&nbsp; &nbsp;
                        <a onClick={returnShoeModel}>
                          {t("PersonalInfo.return")}
                        </a>
                        .
                      </label>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 d-flex align-items-center">
                      <Checkbox
                        checked={checkBoxTwo}
                        className="CheckboxMenu"
                        onChange={checkBoxTwoOnchange}
                      />
                      <label className="checkbox-lable col-md-11 ml-4">
                        {t("PersonalInfo.text3")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  selectedImageData === "/static/media/bag.c049303c.png"
                    ? "d-flex justify-content-center"
                    : "d-flex justify-content-center"
                }`}
              >
                {
                  <button
                    className={`${
                      !checkBoxOne ? "buttonCustomDisable" : "buttonCustom"
                    }`}
                    onClick={handleSubmit}
                    disabled={!checkBoxOne}
                  >
                    {t("PersonalInfo.PersonalInfoButton")}
                  </button>
                }
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};

export default DeliveryForm;
