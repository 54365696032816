import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-5.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
const PrivacyModel = ({ handleCancel, handleOk, isModalVisible }) => {
  const {t} = useTranslation();
  return (
    <>
      <div>
        <Modal
          className="Terms-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={200} height={120} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={200} height={120} />
          </div>
          <div className="terms-logo position-relative" onClick={handleCancel}>
            <img src={logo} height={30} />
          </div>
          <div className="terms-title" style={{ textTransform: "uppercase" }}>
            {t("PrivacyPolicy.title")}
          </div>
          <div className="terms-details mt-4">
            {t("PrivacyPolicy.text")}
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy1")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy2")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy3")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy4")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy5")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy6")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy7")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy8")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy9")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy10")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy11")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy12")}
            </div>
            <div className="privecy-text mt-4 mb-4">
              {t("PrivacyPolicy.Policy13")}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default PrivacyModel;
