import React from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../../../../assets/images/logo.svg";
import closeIcon from "../../../../assets/images/Group 8-5.svg";
import Modal from "antd/lib/modal/Modal";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { config } from "../../../../utils/config";
import {getFromStorage} from "../../../../utils/common";
const ImageModel = ({
  handleCancel,
  handleOk,
  isModalVisible,
  kisokId,
  screenId,
}) => {
  const {t} = useTranslation();
  const currentLanguage = getFromStorage('i18nextLng');
  return (
    <>
      <Modal
        className="image-model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="model-left-logo position-absolute">
          <img src={leftLogo} alt="" width={150} height={100} />
        </div>
        <div className="model-right-logo position-absolute">
          <img src={rightLogo} alt="" width={150} height={100} />
        </div>
        <div className="model-logo position-relative" onClick={handleCancel}>
          <img src={closeIcon} height={30} />
        </div>
        <div className="d-flex justify-content-center flex-column h-100">
          <div className="d-flex justify-content-center">
            <div className="image-model-title mb-5">{t("QrModal.text")}</div>
          </div>
          <div className="image-model-qr d-flex justify-content-center">
            <QRCode
              value={`${config.MOBILE_APP_URL}?kioskId=${kisokId}&screenId=${screenId}&lng=${currentLanguage}`}
              logoImage={logo}
              logoWidth={50}
              logoHeight={25}
              size={120}
              qrStyle={"dots"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageModel;
