import { handleActions } from "redux-actions";
import { requestFail, requestPending, requestSuccess } from "../../../fetch";
import { initialPersonalInfoState } from "./initialPersonalInfoState";
import { PersonalInfoConstant } from "./PersonalInfoConstant";

export const PersonalInfoReducer = handleActions(
  {
    [requestSuccess(PersonalInfoConstant.FETCH_PERSONAL_INFO)]: (
      state,
      action
    ) => {
      return {
        ...state,
        fetchPersonalInfoLoading: false,
        fetchPersonalInfoFailure: false,
        fetchPersonalInfoLoaded: true,
      };
    },
    [requestPending(PersonalInfoConstant.FETCH_PERSONAL_INFO)]: (state) => ({
      ...state,
      fetchPersonalInfoLoading: true,
      fetchPersonalInfoFailure: false,
      fetchPersonalInfoLoaded: false,
    }),
    [requestFail(PersonalInfoConstant.FETCH_PERSONAL_INFO)]: (state) => ({
      ...state,
      fetchPersonalInfoLoading: false,
      fetchPersonalInfoFailure: true,
      fetchPersonalInfoLoaded: true,
    }),

      [requestSuccess(PersonalInfoConstant.GET_PRODUCT_VARIANTS)]: (
          state,
          action
      ) => {
          return {
              ...state,
              getProductVariantsLoding: false,
              getProductVariantsFailure: false,
              getProductVariantsLoaded: true,
          };
      },
      [requestPending(PersonalInfoConstant.GET_PRODUCT_VARIANTS)]: (state) => ({
          ...state,
          getProductVariantsLoding: true,
          getProductVariantsFailure: false,
          getProductVariantsLoaded: false,
      }),
      [requestFail(PersonalInfoConstant.GET_PRODUCT_VARIANTS)]: (state) => ({
          ...state,
          getProductVariantsLoding: false,
          getProductVariantsFailure: true,
          getProductVariantsLoaded: true,
      }),
  },
  initialPersonalInfoState()
);
