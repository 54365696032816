import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Loading.scss";

const Loading = ({ className, mainClass, style, position, center }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;
  return (
    <>
      {window.location.pathname.includes("start") ? (
        <div className="loading spinner-border text-primary" role="status">
          <span className="spin sr-only">Loading...</span>
        </div>
      ) : (
        <div
          className={`loadingBackGroundColor ${mainClass ? mainClass : ""}`}
          style={{ position }}
        >
          <Spin
            size="large"
            className={`spin ${center ? "no-left" : ""} ${className}`}
            style={{ position }}
          />
        </div>
      )}
    </>
  );
};

export default Loading;
