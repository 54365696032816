import {takeLatest,call} from "redux-saga/effects";
import { productConstant } from "./productConstant";
import {request} from "../../../fetch";
import {baseURL} from "../../../apiConsts";

function* fetchAllProduct(action) {
    yield call(
        request({
            type: productConstant.GET_ALL_PRODUCT,
            method: "GET",
            url: `Product`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail,
            baseURL:baseURL,
            data:action.payload.data,
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(productConstant.GET_ALL_PRODUCT, fetchAllProduct);
}