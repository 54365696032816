import React, { createContext, useEffect, useState } from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { checkLogin } from "../utils/redux/modules/Login/loginAction";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage } from "../utils/common";
import { checkPrinterMode } from "../utils/redux/modules/Kiosk/kioskAction";
import StartPage from "./StatePage";
import SelectProductType from "./selectProductType";
import SelectImage from "./selectImage";
import TemporarilyOffline from "./OfflinePage";
import PersonalizeLabel from "./PersonlizeLabel";
import DeliveryForm from "./Personalnfo";
import FinishPage from "./ThankYou";
import PreviewGift from "./PreviewGift";
import { Footer } from "../common/components/Footer";
import LoaderBackground from "../common/components/LoaderBackground";
import BasicInfo from "./BasicInfo";
import TimeModal from "../common/components/TimeModal";
import "./Main.scss";

export const SelectedLug = createContext();
const Main = () => {
  const dispatch = useDispatch();
  const locationId = window.location.pathname.split("/")[2];
  const [isOnline, setIsOnline] = useState(false);
  const [modal, setModal] = useState(false);
  const [Language, setLanguage] = useState();
  const loading = useSelector((state) => state?.kiosk?.checkPrinterModeLoading);
  const loaded = useSelector((state) => state?.kiosk?.checkPrinterModeLoaded);
  useEffect(() => {
    if (window.location.pathname.split("/")[2]) {
      checkPrinterModeOnOff();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.location.replace(`/start/${locationId}`);
    });
  }, [isOnline]);

  const checkLoginData = () => {
    const payload = {
      email: "login@ghirardelli.com",
      password: "Ghirardelli@123",
    };
    dispatch(checkLogin({ data: payload }));
  };

  const checkPrinterModeOnOff = () => {
    const onSuccess = (response) => {
      setIsOnline(response?.isOnline);
    };
    const onFail = (err) => {
      const { data } = err;
      errorMessage(data ? data.title : "Something went wrong!");
      checkLoginData();
      let setTimeouts;
      if (locationId && !isNaN(parseInt(locationId))) {
        setTimeouts = setTimeout(() => {
          window.location.pathname = `/start/${locationId}`;
        }, 1000);
      } else {
        clearTimeout(setTimeouts);
      }
    };
    let kioskId;
    kioskId = locationId;
    const payload = {
      kioskId: kioskId,
      brandId: 0,
      productId: 0,
      productVariantId: 0,
    };
    dispatch(checkPrinterMode({ data: payload, onSuccess, onFail }));
  };

  const ROUTE_ARRAY = ({ locationId, isOnline }) => [
    {
      id: `${isOnline ? `TemporarilyOffline` : `StartPage`}`,
      component: loaded
        ? isOnline
          ? StartPage
          : TemporarilyOffline
        : LoaderBackground,
      path: `/start/${locationId}`,
      exact: true,
      isPublicRoute: true,
    },
    {
      id: `FinishPage`,
      component: FinishPage,
      path: `/success/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    {
      id: `DeliveryForm`,
      component: DeliveryForm,
      path: `/personalInfo/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    {
      id: `PersonalizeLabel`,
      component: PersonalizeLabel,
      path: `/personalizeLabel/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    {
      id: `PreviewGift`,
      component: PreviewGift,
      path: `/previewGift/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    {
      id: `SelectImage`,
      component: SelectImage,
      path: `/selectImage/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    {
      id: `SelectProductType`,
      component: SelectProductType,
      path: `/selectProductType/${locationId}`,
      exact: true,
      isPublicRoute: false,
    },
    // {
    //   id: `BasicInfo`,
    //   component: BasicInfo,
    //   path: `/basicInfo/${locationId}`,
    //   exact: true,
    //   isPublicRoute: false,
    // },
  ];

  const RESTICTED_ROUTES = ["start", "success"];

  const setWindowEvent = () => {
    const showModalHandler = () => {
      if (RESTICTED_ROUTES.some((e) => window.location.pathname.includes(e)))
        time = setTimeout(showModalHandler, 60000);
      else {
        setModal(true);
      }
    };

    const resetModal = () => {
      clearTimeout(time);
      time = setTimeout(showModalHandler, 60000);
    };

    let time = setTimeout(showModalHandler, 60000);

    const EVENTS = ["mousemove", "touchmove"];

    EVENTS.forEach((eachEvent) => {
      document.addEventListener(eachEvent, resetModal);
    });
  };

  useEffect(() => {
    window.addEventListener("load", setWindowEvent);
  }, []);

  return (
    <>
      <SelectedLug.Provider value={{ Language, setLanguage }}>
        <div className="app">
          <main className={`d-flex`} style={{ overflow: "hidden" }}>
            <div className={`w-100 h-100 mainContent d-flex`}>
              <BrowserRouter>
                <Switch>
                  {ROUTE_ARRAY({ isOnline, locationId })
                    .filter((e, index) => (!isOnline ? e.isPublicRoute : true))
                    .map((e, index) => (
                      <Route
                        path={e.path}
                        render={(props) => <e.component {...props} />}
                        exact={e.exact}
                        key={index}
                      />
                    ))}
                  <Redirect to={`/start/${locationId}`} />
                </Switch>
              </BrowserRouter>
            </div>
            {modal && (
              <TimeModal
                handleOk={() => {
                  setModal(false);
                  setWindowEvent();
                }}
                isModalVisible={modal}
              />
            )}
            <Footer textAlign="left" />
          </main>
        </div>
      </SelectedLug.Provider>
    </>
  );
};

export default Main;
