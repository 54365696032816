import React from "react";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-5.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";
import { useTranslation } from "react-i18next";
import './index.scss';
const ReturnModel = ({ handleCancel, handleOk, isModalVisible }) => {
  const { t} = useTranslation();
  return (
    <>
      <div>
        <Modal
          className="Return-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={200} height={120} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={200} height={120} />
          </div>
          <div className="terms-logo position-relative" onClick={handleCancel}>
            <img src={logo} height={30} />
          </div>
          <div className="terms-title" style={{ textTransform: "uppercase" }}>
            {t("ReturnPolicy.title")}
          </div>
          <div className="d-flex justify-content-start align-items-between return-details mt-5">
            {t("ReturnPolicy.text")}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReturnModel;
