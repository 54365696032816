import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { all } from "redux-saga/effects";
import kioskSaga from "./Kiosk/kioskSaga";
import { kioskReducer } from "./Kiosk/kioskReducer";
import { productReducer } from "./Product/productReducer";
import productSaga from "./Product/productSaga";
import imageReducer from "./imageHandler/imageReducer";
import { LoginReducer } from "./Login/loginReducer";
import loginSaga from "./Login/loginSaga";
import personalInfoSaga from "./PersonalInfo/PersonalInfoSaga";
import { PersonalInfoReducer } from "./PersonalInfo/PersonalInfoReducer";
export const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    kiosk: kioskReducer,
    personalInfo: PersonalInfoReducer,
    product: productReducer,
    imageData: imageReducer,
    login: LoginReducer,
  });

export const rootSaga = function*() {
  yield all([kioskSaga(), productSaga(), loginSaga(), personalInfoSaga()]);
};
