import { takeLatest, call } from "redux-saga/effects";
import { PersonalInfoConstant } from "./PersonalInfoConstant";
import { request } from "../../../fetch";
import { baseURL } from "../../../apiConsts";
import { productConstant } from "../Product/productConstant";
import { KioskConstant } from "../Kiosk/kioskConstant";

function* submitProduct(action) {
  yield call(
    request({
      type: PersonalInfoConstant.FETCH_PERSONAL_INFO,
      method: "POST",
      url: `KioskEntry`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
      baseURL: baseURL,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: action.payload.data,
    }),
    action
  );

}

function* getProductVariant(action) {
  yield call(
    request({
      type: PersonalInfoConstant.GET_PRODUCT_VARIANTS,
      method: "GET",
      url: `Product/GetProductVariantsByProductId/${action?.payload?.data?.id}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
      baseURL: baseURL,
      data: action.payload.data,
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(PersonalInfoConstant.FETCH_PERSONAL_INFO, submitProduct);
  yield takeLatest(
    PersonalInfoConstant.GET_PRODUCT_VARIANTS,
    getProductVariant
  );
}
