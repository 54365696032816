import React from "react";
import { errorModal } from "../../../../utils/common";

const Layers = React.forwardRef(
  ({ properties, selected, setAction, order, showModal }, ref) => {
    const renderComponent = () => {
      const setActionFn = setAction;
      return (
        <TextLayer
          properties={properties}
          setAction={setActionFn}
          selected={selected}
          order={order}
          ref={ref}
          showModal={showModal}
        />
      );
    };

    if (properties && Object.keys(properties)?.length !== 0) {
      const centerX = properties.width / 2;
      const centerY = properties.height / 2;

      if (properties.coordinates.x < 0 || properties.coordinates.y < 0) {
        errorModal("The text is outside the image area");
      }
      return (
        <g
          transform={`translate(
                      ${properties.coordinates.x},
                      ${properties.coordinates.y}
                    ) rotate(0,
                      ${centerX},
                      ${centerY})`}
        >
          {renderComponent()}
        </g>
      );
    }
    return false;
  }
);

const TextLayer = React.forwardRef(
  ({ properties, setAction, selected, order, showModal, data }, ref) => {
    const textRef = ref;
    const handleSetAction = (e) => {
      setAction(e)(order, "move", textRef);
    };
    /*
      Add space on tspan height to make sure that the text is inside the box
    */
    const height = properties.text_height;
    const containerWidth = properties.width;
    const containerHeight = properties.height;

    const handleSetActionTouch = (e) => {
      const obj = {
        clientX: e.touches[0].clientX,
        clientY: e.touches[0].clientY,
      };
      setAction(obj)(order, "move", textRef);
    };
    return (
      <g
        onMouseDown={handleSetAction}
        onTouchStart={() => handleSetActionTouch}
        style={{ cursor: "move" }}
      >
        <g>
          <text
            className="class"
            ref={textRef}
            fill={properties.color}
            fontFamily={properties.fontFamily}
            fontSize={properties.fontSize * 4}
            x={0}
            y={
              containerHeight > 100
                ? containerHeight - 25
                : containerHeight - 10
            }
          >
            <tspan fontWeight="bold">{properties.title}</tspan>
          </text>
        </g>
        {properties.title && selected && (
          <g>
            <rect
              x="0"
              y="0"
              width={containerWidth}
              height={containerHeight}
              style={{ strokeWidth: "5", stroke: selected ? "#FFF" : "" }}
            />
            <g onClick={showModal} style={{ cursor: "pointer" }}>
              <g
                transform={`translate(${
                  containerWidth > 1200
                    ? "1125"
                    : properties.coordinates.x > 100
                    ? `${containerWidth - 55}`
                    : containerWidth
                }, ${
                  properties.coordinates.y > 1000 ? "-100" : containerHeight
                })`}
              >
                <rect
                  x="-25"
                  y="0"
                  width={100}
                  height={100}
                  fill="#BA4E4E"
                  rx={20}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  fill="white"
                  width={100}
                  height={100}
                  x="-10"
                  y="10"
                >
                  <path
                    d="M39,5.8h-5.9h-5.3V2.2c0-0.8-0.6-1.4-1.4-1.4H12.9c-0.8,0-1.4,0.6-1.4,1.4v3.6H6.2H2c-0.8,0-1.4,0.6-1.4,1.4S1.2,8.6,2,8.6
	h2.8v32.2c0,0.8,0.6,1.4,1.4,1.4h26.9c0.8,0,1.4-0.6,1.4-1.4V8.6H39c0.8,0,1.4-0.6,1.4-1.4S39.8,5.8,39,5.8z M14.3,3.6H25v2.2H14.3
	V3.6z M31.7,39.4H7.6V8.6h5.3h13.5h5.3V39.4z M14.3,13.1v20.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V13.1c0-0.8,0.6-1.4,1.4-1.4
	S14.3,12.3,14.3,13.1z M21.1,13.1v20.2c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V13.1c0-0.8,0.6-1.4,1.4-1.4S21.1,12.3,21.1,13.1z
	 M26.4,11.7c0.8,0,1.4,0.6,1.4,1.4v20.2c0,0.8-0.6,1.4-1.4,1.4S25,34,25,33.3V13.1C25,12.3,25.6,11.7,26.4,11.7z"
                  />
                </svg>
              </g>
            </g>
          </g>
        )}
      </g>
    );
  }
);

export default Layers;
// translate(80, 00);
