import React, { Suspense } from "react";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./common/styles/global.scss";
import Main from "./pages/Main";
import { Provider } from "react-redux";
import { store } from "./utils/ReduxService";
import { Route, Switch, BrowserRouter } from "react-router-dom";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

const App = () => (
  <Provider store={store}>
    <Suspense fallback={Loader}>
      <BrowserRouter>
      <Switch>
        <Route
          path={"/"}
          render={(props) => <Main {...props} />}
        />
      </Switch>
      </BrowserRouter>
    </Suspense>
  </Provider>
);

export default App;
