import { handleActions } from "redux-actions";
import { requestFail, requestPending, requestSuccess } from "../../../fetch";
import { initialLoginState } from "./initialLoginState";
import { LoginConstant } from "./loginConstant";

export const LoginReducer = handleActions(
  {
    [requestSuccess(LoginConstant.CHECK_LOGIN)]: (state, action) => {
      return {
        ...state,
        checkLoginLoading: false,
        checkLoginFailure: false,
        checkLoginLoaded: true,
      };
    },
    [requestPending(LoginConstant.CHECK_LOGIN)]: (state) => ({
      ...state,
      checkLoginLoading: true,
      checkLoginFailure: false,
      checkLoginLoaded: false,
    }),
    [requestFail(LoginConstant.CHECK_LOGIN)]: (state) => ({
      ...state,
      checkLoginLoading: false,
      checkLoginFailure: true,
      checkLoginLoaded: true,
    }),
  },
  initialLoginState()
);
