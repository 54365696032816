import React, { useEffect } from "react";
import { clearStore } from "../../utils/redux/modules/imageHandler/action";
import logo from "../../assets/images/Step 3 - custom image upload modal.jpg";
import person from "../../assets/images/person.jpg";
import tin from "../../assets/images/tin.png";
import BackgroundImage from "../../common/components/BackgroundImage";
import MainBox from "../../common/components/MainBox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./index.scss";

const FinishPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationId = useSelector((state) => state?.kiosk?.details?.kioskId);
  const selectedImageData = useSelector(
    (state) => state.imageData.setSelectedImageData.image
  );
  const selectedCustomizeData = useSelector(
    (state) => state.imageData.setCustomizeImageData
  );
  
  useEffect(() => {
    const timeOut = setTimeout(() => {
      window.location.replace(`/start/${locationId}`);
    }, 20000);
  }, []);

  return (
    <>
      <BackgroundImage>
        <div
          className={`d-flex w-100 h-100 align-items-center justify-content-center`}
        >
          <MainBox showSideImage={false}>
            <div className="finish">
              <div className="finish-main h-100">
                <div className="finish-title mt-5 d-flex justify-content-center">
                  {t("Thankyou.title")}
                </div>
                <div className="finish-center">
                  <div className="finish-center-right m-5">
                    <div className="finish-center-right-text">
                      {t("Thankyou.text")}
                    </div>
                  </div>
                  <div className="finish-center-left d-flex justify-content-center align-item-center">
                    <img
                      src={selectedImageData}
                      height={
                        selectedImageData === "/static/media/bag.c049303c.png"
                          ? "378px"
                          : "275px"
                      }
                      width={
                        selectedImageData === "/static/media/bag.c049303c.png"
                          ? ""
                          : "390px"
                      }
                    />
                    <div
                      className={`${
                        selectedImageData === "/static/media/bag.c049303c.png"
                          ? "previewGift-SubImage"
                          : "previewGift-SubImage-tin"
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: selectedCustomizeData.svgData,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </MainBox>
        </div>
      </BackgroundImage>
    </>
  );
};

export default FinishPage;
