import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "antd/lib/modal/Modal";
import logo from "../../../../assets/images/Group 8-7.svg";
import leftLogo from "../../../../assets/images/modal-bg-left.png";
import rightLogo from "../../../../assets/images/modal-bg-right.png";

const DeleteImageModal = ({ handleCancel, handleOk, isModalVisible }) => {
  const {t} = useTranslation();
  return (
    <>
      <div>
        <Modal
          className="warning-model"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="model-left-logo position-absolute">
            <img src={leftLogo} alt="" width={180} height={120} />
          </div>
          <div className="model-right-logo position-absolute">
            <img src={rightLogo} alt="" width={180} height={120} />
          </div>
          <div className="delivery-logo">
            <img src={logo} alt="" width={100} height={93} />
          </div>
          <div className="delivery-model-main d-flex justify-content-center flex-column align-items-center h-100">
            <div className="delivery-logo"></div>
            <div
              className="delivery-model-title"
              style={{ textTransform: "uppercase" }}
            >
              {t("common.opps")}
            </div>
            <div
              className="delivery-model-details mt-2 mb-2"
              style={{ textAlign: "center" }}
            >
              {t("deleteImageModal.text")}
            </div>
            <div className="delivery-model-botton">
              <button
                className="buttonCustom mt-3 mr-3"
                onClick={handleCancel}
                style={{
                  backgroundColor: "transparent",
                  color: "#213547",
                  boxShadow: "0px 2px 5px 5px RGBA(0, 0, 0, 0.04)",
                }}
              >
                {t("deleteImageModal.cancleButton")}
              </button>
              <button
                className="buttonCustom mt-3"
                onClick={handleOk}
                style={{ backgroundColor: "#BA4E4E " }}
              >
                {t("deleteImageModal.deleteButton")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DeleteImageModal;