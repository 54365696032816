import { takeLatest, call } from "redux-saga/effects";
import { KioskConstant } from "./kioskConstant";
import { request } from "../../../fetch";
import { baseURL } from "../../../apiConsts";

function* fetchPrinterMode(action) {
  yield call(
    request({
      type: KioskConstant.CHECK_PRINTER_MODE,
      method: "GET",
      url: `Kiosk/${action.payload.data.kioskId}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
      baseURL: baseURL,
      data: action.payload.data,
    }),
    action
  );
}
function* fetchKioskTranslate(action) {
  yield call(
    request({
      type: KioskConstant.KIOSK_TRANSLATE,
      method: "GET",
      url: `Kiosk/TranslateText?text=${action.payload.data.text}&fromLangCode=${action.payload.data.fromLangCode}&toLangCode=${action.payload.data.toLangCode}`,
      success: action.payload.onSuccess,
      fail: action.payload.onFail,
      baseURL: baseURL,
      data: action.payload.data,
    }),
    action
  );
}

export default function* rootSaga() {
  yield takeLatest(KioskConstant.CHECK_PRINTER_MODE, fetchPrinterMode);
  yield takeLatest(KioskConstant.KIOSK_TRANSLATE, fetchKioskTranslate);
}
